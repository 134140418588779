.controller('globalCtrl', function($scope, $translate) {
    $scope.changeLanguage = function (langKey) {
        $translate.use(langKey);
    };
})

.controller('homeCtrl', function($scope) {
    alignReviews(true);
    $(".icon").on("mouseenter", function(){
        $(this).fadeTo(100,0.50, function() {
            $(this).attr("src", $(this).attr('data-new-img'));
        }).fadeTo(500,1);
    });

    $(".icon").on("mouseleave", function(){
        $(this).fadeTo(100,0.50, function() {
            $(this).attr("src", $(this).attr('data-old-img'));
        }).fadeTo(500,1);
    });
})

.controller('productsCtrl', function($scope) {

})

.controller('contactCtrl', ['$scope', '$http', function ($scope, $http) {
    $scope.num1 = Math.floor((Math.random() * 10) + 1);
    $scope.num2 = Math.floor((Math.random() * 10) + 1);
    var result_captcha = $scope.num1 + $scope.num2;
    $scope.invalid_captcha = false;

    $scope.submit = function() {
        if ($scope.result_captcha != result_captcha) {
            $scope.invalid_captcha = true;
            return;
        }
        $scope.invalid_captcha = false;

        var data = {
            name: $scope.name,
            email: $scope.email,
            message: $scope.msg
        };

        $http({
            method: 'post',
            url: '/mail_sender.php',
            data: data
        }).then(function () {
            $scope.sent  = true;
        })
    }
}]);