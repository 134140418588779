var app = angular.module('app', ['ngRoute', 'pascalprecht.translate'])

.config(['$routeProvider', '$locationProvider', '$translateProvider', function ($routeProvider, $locationProvider, $translateProvider) {
    // $translateProvider.useLocalStorage();

    $translateProvider.useStaticFilesLoader({
        prefix: '/languages/',
        suffix: '.json'
    });

    $translateProvider.preferredLanguage('en');

    $routeProvider
        .when('/', {
            templateUrl: 'templates/home.html',
            controller: 'homeCtrl'
        })
        .when('/products', {
            templateUrl: 'templates/product-category.html',
            controller: 'productsCtrl'
        })
        .when('/about', {
            templateUrl: 'templates/about.html'
        })
        .when('/contact', {
            templateUrl: 'templates/contact.html',
            controller: 'contactCtrl'
        })
        .when('/faq', {
            templateUrl: 'templates/faq.html'
        })
        .when('/partners', {
            templateUrl: 'templates/partners.html'
        })
        .otherwise({
            redirectTo: '/'
        });
    $locationProvider.html5Mode(true);

}])

.run(['$rootScope','$location', function($rootScope, $location) {
    $rootScope.$on('$routeChangeSuccess', function(e, current) {
        $rootScope.currentUrl = $location.path();
    });
}])